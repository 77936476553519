import { Routes } from '@angular/router';

export const inventoryRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./inventory-page/inventory-page.component').then((m) => m.InventoryPageComponent),
  },
  {
    path: 'search-products',
    loadComponent: () =>
      import('./inventory-search-products-page/inventory-search-products-page.component').then(
        (m) => m.InventorySearchProductsPageComponent
      ),
  },
  {
    path: 'add-product',
    loadComponent: () =>
      import('./inventory-add-product-page/inventory-add-product-page.component').then(
        (m) => m.InventoryAddProductPageComponent
      ),
  },
];
