import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Image } from '../models/release';
import { ShopifyProduct } from '../models/shopify-product';
import { Events } from '../models/shopify-product';
import { SalesChannel } from '../models/sales-channel';

@Injectable({
  providedIn: 'root',
})
export class ShopifyService {
  constructor(private http: HttpClient) {}

  addNewStore(store: string, accessToken: string, apiSecretKey: string): Observable<{ oAuthUrl: string }> {
    const endPoint = accessToken ? 'auth/shopify/install-customapp' : 'auth/shopify/install';
    return this.http.post<{ oAuthUrl: string }>(endPoint, {
      store,
      accessToken,
      apiSecretKey,
    });
  }

  createShopifySalesChannel(shop: string, companyId: string): Observable<SalesChannel> {
    const endPoint = 'shopify/create-shopify-channel';
    return this.http.post<SalesChannel>(endPoint, { shop, companyId });
  }

  authorizeUrl(oauth_url: string) {
    document.location.href = oauth_url;
  }

  async getProduct(productId: string, salesChannelId: string): Promise<ShopifyProduct> {
    return this.http.get<ShopifyProduct>(`product/shopify/${productId}?salesChannelId=${salesChannelId}`).toPromise();
  }

  async getProductImages(productId: string, salesChannelId: string): Promise<Image[]> {
    return this.http.get<Image[]>(`product/shopify/${productId}/images?salesChannelId=${salesChannelId}`).toPromise();
  }

  async getEvents(externalOrderId: string, salesChannelId: string): Promise<Events[]> {
    return this.http
      .get<Events[]>(`orders/shopify/events?externalOrderId=${externalOrderId}&salesChannelId=${salesChannelId}`)
      .toPromise();
  }

  async getMetaFields(shop: string): Promise<any> {
    return this.http.get<any>(`shopify/meta-fields/${shop}`).toPromise();
  }

  changeShopifyShippingStatus(
    orderId: string,
    externalOrderId: string,
    salesChannelId: string,
    trackId: string,
    trackUrl?: string,
    shippingCompany?: string,
    message?: string,
    isLocalPickup?: boolean
  ): Observable<{ statusOrder: string }> {
    return this.http.post<{ statusOrder: string }>(`orders/${orderId}/shopify/change-status`, {
      externalOrderId,
      salesChannelId,
      trackId,
      trackUrl,
      shippingCompany,
      message,
      isLocalPickup,
    });
  }
}
