import { Routes } from '@angular/router';

export const adminRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./companies-table/companies-table.component').then((m) => m.CompaniesTableComponent),
  },
  {
    path: 'companies',
    loadComponent: () => import('./companies-table/companies-table.component').then((m) => m.CompaniesTableComponent),
  },
  {
    path: 'company-tier-setting',
    loadComponent: () =>
      import('@/admin/company-tier-setting-table/company-tier-setting-table.component').then(
        (m) => m.CompanyTierSettingTableComponent
      ),
  },
  {
    path: 'companies/:id/users',
    loadComponent: () =>
      import('./admin-users-table/admin-users-table.component').then((m) => m.AdminUsersTableComponent),
  },
  {
    path: 'companies/:id/stats',
    loadComponent: () => import('./company-stats/company-stats.component').then((m) => m.CompanyStatsComponent),
  },
  {
    path: 'shipping-rates-management',
    loadComponent: () =>
      import('./shipping-rates-management/shipping-rates-management.component').then(
        (m) => m.ShippingRatesManagementComponent
      ),
  },
  {
    path: 'shipping-carrier-credentials',
    loadComponent: () =>
      import('./shipping-carrier-credentials-table/shipping-carrier-credentials-table.component').then(
        (m) => m.ShippingCarrierCredentialsTableComponent
      ),
  },
];
