import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Injectable } from '@angular/core';
import { ShippingCarrier } from '../models/shipping-carrier';
import { GeneralShippingSettingsOption } from '../models/company';
@Injectable({
  providedIn: 'root',
})
export class ShippingCarrierService extends CrudService<ShippingCarrier> {
  constructor(http: HttpClient) {
    super(http, 'shipping-carriers');
  }

  public async getMyShippingCarriers(): Promise<ShippingCarrier[]> {
    const data = await this.http.get<ShippingCarrier[]>(`${this.endpoint}/`).toPromise();

    return data.map((item) => ({ ...item }));
  }

  public async getMyShippingCarriersWithOptions(): Promise<GeneralShippingSettingsOption[]> {
    const data = await this.http.get<GeneralShippingSettingsOption[]>(`${this.endpoint}/carriers-options`).toPromise();

    return data.map((item) => ({ ...item }));
  }

  public async getNumberOfShippingCarriersByCompanyId(companyId: string): Promise<number> {
    return this.http.get<number>(`${this.endpoint}/number-of-carriers/${companyId}`).toPromise();
  }
}
