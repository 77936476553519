import { BaseModel } from './base-model';
import { Crate } from './crate';
import { SalesChannel, SalesChannelType } from './sales-channel';
import { Address, CustomsInfo, ShippingCarrierType } from './shipping-carrier';

export interface ShippingAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryAlpha2Code: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  zipcode: string;
  companyName: string;
}
export interface Order extends BaseModel {
  externalId: string;
  companyIndex: number;
  channel: SalesChannelType;
  salesChannelId: string;
  waybillIds?: string[];
  salesChannel?: Pick<SalesChannel, '_id' | 'name'>;
  buyer: OrderPerson;
  seller: OrderPerson;
  created: Date;
  lastActivity?: Date;
  externalStatus: string;
  status: OrderStatus;
  isFlagged: boolean;
  internalArchived: boolean;
  total: OrderBalance;
  localPickup?: boolean;
  shipping: OrderShipping;
  shippingAddress?: ShippingAddress;
  archived: boolean;
  additionalInstructions: string;
  fee: OrderBalance;
  items: Item[];
  itemsLength: number;
  customerName: string;
  crateId: string;
  crate: Crate;
  tab: number;
  notes: Note[];
  timeline: TimelineEvent[];
  customMessage?: string;
  flagExist?: boolean;
  flagIconPath?: string;
  shopifyDisplayId: string;
  utils?: {
    bandcampInvoice?: string;
  };
}

export interface TimelineEvent {
  username: string;
  type?: TimelineEventType;
  timestamp: Date;
  crateName?: string;
  crateTab?: number;
  shopifyMessage?: string;
}

export interface Note {
  username: string;
  createdAt: Date;
  text: string;
}

export interface Release {
  id: string;
  description: string;
  thumbnail: string;
  itemUrl?: string;
  handle?: string;
}

export enum AssetCondition {
  mint = 'Mint (M)',
  near_mint = 'Near Mint (NM or M-)',
  very_good_plus = 'Very Good Plus (VG+)',
  very_good = 'Very Good (VG)',
  good_plus = 'Good Plus (G+)',
  good = 'Good (G)',
  fair = 'Fair (F)',
  poor = 'Poor (P)',
  generic = 'Generic',
  shopify = '-',
}

export interface Item {
  _id: string;
  release: Release;
  price: OrderBalance;
  mediaCondition?: AssetCondition;
  sleeveCondition?: AssetCondition;
  itemLocation?: string;
  conditionComments?: string;
  privateComments?: string;
  pickingStatus: PickingStatus;
  reportReason?: ReportReason;
  reportMessage?: string;
  quantity?: number;
  orderId?: string;
  externalOrderId?: string;
  salesChannelId?: string;
  tab?: number;
}

export interface OrderPerson {
  id: string;
  username: string;
  name?: string;
  phone?: string;
  email?: string;
}

export interface OrderShipping {
  currency: string;
  method: string;
  value: number;
  address: string;
  packages?: OrderPackage[];
  parsedAddress?: Address;
  shipmentUuid?: string;
  orderExternalId?: string;
}
export enum PackageUnit {
  Pound = 'pound',
  Ounce = 'ounce',
  Gram = 'gram',
  Kilogram = 'kilogram',
}

export interface ShipmentCost {
  currency: string;
  amount: number;
  amount_without_markup?: number;
}

export interface OrderPackage {
  uuid?: string;
  label: string;
  weight: number;
  weightUnit: PackageUnit;
  shippingOptionCode?: string;
  shippingOptionName?: string;
  shippingOptionType?: ShippingCarrierType;
  labelUrl?: string; // valid for 30 days since generation
  labelCreated?: Date;
  formDownloadUrl?: string; // customs form
  trackingId?: string;
  trackingUrl?: string;
  country?: string;
  customsInfo?: CustomsInfo;
  waybillId?: string;
  carrierId?: string;
  carrierUUId?: string;
  label_id?: string;
  shipment_cost?: ShipmentCost;
  labelStatus?: LabelStatus;
  parcelType?: string;
}

// used for passing data between views
export interface ShippingPackage {
  index: number;
  weight: string;
  weightUnit: PackageUnit;
}

export enum ShippingType {
  TrackedMaxibrev = 'LY',
  MyPackHome = '17',
  MyPackCollect = '19',
  RekommanderetBrev = 'RE',
  UntrackedMaxibrev = 'UZ',
}

export interface OrderBalance {
  currency: string;
  value: number;
}

export enum LabelStatus {
  voided = 'voided',
}

export enum OrderStatus {
  newOrder = 'newOrder',
  onHold = 'onHold',
  picked = 'picked',
  packed = 'packed',
  shipped = 'shipped',
  pickUp = 'pickUp',
}

export enum DiscogsOrderStatus {
  newOrder = 'New Order',
  buyerContacted = 'Buyer Contacted',
  invoiceSent = 'Invoice Sent',
  paymentPending = 'Payment Pending',
  paymentReceived = 'Payment Received',
  shipped = 'Shipped',
  merged = 'Merged',
  orderChanged = 'Order Changed',
  refundSent = 'Refund Sent',
  cancelled = 'Cancelled',
  cancelledNonPayingBuyer = 'Cancelled (Non-Paying Buyer)',
  cancelledItemUnavailable = 'Cancelled (Item Unavailable)',
  cancelledPerBuyersRequest = "Cancelled (Per Buyer's Request)",
  cratebaseImportedOrder = 'Cratebase imported order',
}

export interface DiscogsOrder {
  id: string;
  uri: string;
  status: DiscogsOrderStatus;
  next_status: DiscogsOrderStatus[];
}

export enum TimelineEventType {
  createdOrder = 'createdOrder',
  newOrder = 'newOrder',
  onHold = 'onHold',
  buyerContacted = 'buyerContacted',
  invoiceSent = 'invoiceSent',
  paymentPending = 'paymentPending',
  paymentReceived = 'paymentReceived',
  shipped = 'shipped',
  merged = 'merged',
  orderChanged = 'orderChanged',
  refundSent = 'refundSent',
  cancelled = 'cancelled',
  cancelledNonPayingBuyer = 'cancelledNonPayingBuyer',
  cancelledItemUnavailable = 'cancelledItemUnavailable',
  cancelledPerBuyersRequest = 'cancelledPerBuyersRequest',
  picked = 'picked',
  packed = 'packed',
  pickUp = 'pickUp',
  received = 'received',
  assignedToCrate = 'assignedToCrate',
  assignedToDefaultCrate = 'assignedToDefaultCrate',
  changedCrate = 'changedCrate',
  removeCrate = 'removeCrate',
  eventShopify = 'eventShopify',
  messageShopify = 'messageShopify',
  cratebaseImportedOrder = 'cratebaseImportedOrder',
}

export interface GroupedOrders {
  groupBy: 'status' | 'crateId';
  groupByValue: string;
  ordersCount: number;
  itemsCount: number;
  ordersFlaggedCount: number;
  itemsFlaggedCount: number;
}

export interface StatisticsOrders {
  groupByValue: string;
  ordersCount: number;
  itemsCount: number;
}

export interface ReportData {
  reportReason: ReportReason;
  details?: string;
}

export enum ReportReason {
  differentLocation = 'differentLocation',
  missing = 'missing',
  notMatchingDescription = 'notMatchingDescription',
  other = 'other',
}

export enum PickingStatus {
  notPicked = 'notPicked',
  picked = 'picked',
  reported = 'reported',
}

export enum ShopifyFulfillmentOrderStatus {
  pending = 'pending',
  open = 'open',
  success = 'success',
  cancelled = 'cancelled',
  error = 'error',
  failure = 'failure',
}
