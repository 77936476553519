import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserRole, UserRoleAll } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  private authService = inject(AuthService);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowedRoles: UserRole[] = route.data.allowedRoles ?? UserRoleAll;
    if (allowedRoles?.includes(this.authService.user.role)) {
      return true;
    }
    return this.router.navigate(['/orders']);
  }
}
