import { Component } from '@angular/core';
import { DiscogsOrderStatus, Order, OrderStatus, ShopifyFulfillmentOrderStatus } from '../../shared/models/order';
import { DiscogsApiService } from '../../shared/services/discogs-api.service';
import { ToastService } from '../../shared/services/toast.service';
import { OrderService } from '../../shared/services/order.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NasdiscApiService } from '../../shared/services/nasdisc-api.service';
import { ShopifyService } from '../../shared/services/shopify.service';
import { finalize } from 'rxjs/operators';
import { EbayService } from '../../shared/services/ebay.service';
import { DiscogsService } from '../../shared/services/discogs.service';
import { BandcampService } from '../../shared/services/bandcamp.service';

@UntilDestroy()
@Component({
  selector: 'app-mark-order-shipped',
  templateUrl: './mark-order-shipped-component.html',
  styleUrls: ['./mark-order-shipped-component.scss'],
})
export class MarkOrderShippedComponent {
  constructor(
    private discogsApiService: DiscogsApiService,
    private discogsService: DiscogsService,
    private nasdiscApiService: NasdiscApiService,
    private toast: ToastService,
    private location: Location,
    private orderService: OrderService,
    private translateService: TranslateService,
    private shopifyService: ShopifyService,
    private ebayService: EbayService,
    private bandcampService: BandcampService
  ) {}

  public async markAsShipped(order: Order, customMessage, redirect: boolean = true) {
    // change order status to Shipped locally and on Discogs
    try {
      const discogsOrder = await this.discogsApiService.getOrder(order.externalId, order.salesChannelId);
      if (!discogsOrder.next_status.includes(DiscogsOrderStatus.shipped)) {
        // check if we are allowed to set the status to Shipped
        await this.translateService
          .get('toast.error.cannotSetOrderShippedDiscogs')
          .pipe(untilDestroyed(this))
          .subscribe((res: string) => {
            throw Error(res);
          });
      }
      await this.discogsService.changeOrderStatus(
        order.externalId,
        order.salesChannelId,
        DiscogsOrderStatus.shipped,
        customMessage
      );
      this.toast.showSuccess('toast.success.orderChangedStatus', true, { status: OrderStatus.shipped });
    } catch (e: any) {
      this.toast.showWarning(
        'toast.warning.orderChangedStatusWithError',
        true,
        { status: OrderStatus.shipped, salesChannel: 'Discogs', error: e.error?.message },
        true
      );
    } finally {
      await this.orderService.updateOne(order._id, {
        status: OrderStatus.shipped,
        shipping: order.shipping,
      });
      if (redirect) this.location.back();
    }
  }

  public async markAsShippedNasdisc(order: Order, redirect: boolean = true) {
    // change order status to Shipped locally and on Nasdisc
    try {
      await this.nasdiscApiService.changeOrderStatus(
        order.salesChannelId,
        order.shipping.packages[0].trackingId,
        order.externalId,
        order.shipping.packages[0].shippingOptionName
      );
      this.toast.showSuccess('toast.success.orderChangedStatus', true, { status: OrderStatus.shipped });
    } catch (e: any) {
      this.toast.showWarning(
        'toast.warning.orderChangedStatusWithError',
        true,
        { status: OrderStatus.shipped, salesChannel: 'Nasdisc', error: e.error?.message },
        true
      );
    } finally {
      await this.orderService.updateOne(order._id, {
        status: OrderStatus.shipped,
        shipping: order.shipping,
      });
      if (redirect) this.location.back();
    }
  }

  public async markAsShippedShopify(
    order: Order,
    redirect: boolean = true,
    messageToCustomer?: string,
    trackInfo?: string
  ) {
    this.shopifyService
      .changeShopifyShippingStatus(
        order._id,
        order.externalId,
        order.salesChannelId,
        !trackInfo ? order.shipping.packages[0]?.trackingId : trackInfo,
        order.shipping.packages[0]?.trackingUrl ?? '',
        order.shipping.packages[0]?.shippingOptionName ?? '',
        messageToCustomer,
        order.localPickup
      )
      .pipe(
        finalize(async () => {
          await this.orderService.updateOne(order._id, { status: OrderStatus.shipped });
          if (redirect) this.location.back();
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res: any) => {
          if (res.statusOrder === ShopifyFulfillmentOrderStatus.success) {
            this.toast.showSuccess('toast.success.shopify.updatedTrackNumber');
          } else {
            this.toast.showError('toast.error.trackNumberNotUpdated');
          }
        },
        error: (err) => {
          this.toast.showError(err.error.message);
        },
      });
  }

  public async markAsShippedEbay(
    order: Order,
    redirect: boolean = true,
    messageToCustomer?: string,
    trackInfo?: string
  ) {
    this.ebayService
      .changeEbayShippingStatus(
        order._id,
        order.externalId,
        order.salesChannelId,
        !trackInfo ? order.shipping.packages[0]?.trackingId : trackInfo,
        order.shipping.packages[0]?.trackingUrl ?? '',
        order.shipping.packages[0]?.shippingOptionName ?? '',
        messageToCustomer ?? ''
      )
      .pipe(
        finalize(async () => {
          await this.orderService.updateOne(order._id, { status: OrderStatus.shipped });
          if (redirect) this.location.back();
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res: any) => {
          if (res.statusOrder === ShopifyFulfillmentOrderStatus.success) {
            this.toast.showSuccess('toast.success.ebay.updatedTrackNumber');
          } else {
            this.toast.showError('toast.error.trackNumberNotUpdated');
          }
        },
        error: (err) => {
          this.toast.showError(err.error.message);
        },
      });
  }

  public async markAsShippedBandcamp(order: Order, redirect: boolean = true, messageToCustomer?: string) {
    this.bandcampService
      .changeShippingStatus(
        order._id,
        order.externalId,
        order.salesChannelId,
        order.shipping.packages[0]?.trackingId ?? '',
        order.shipping.packages[0]?.shippingOptionName ?? '',
        messageToCustomer
      )
      .subscribe({
        next: async (res: any) => {
          if (res.success) {
            await this.orderService.updateOne(order._id, { status: OrderStatus.shipped });
            if (redirect) this.location.back();
            this.toast.showSuccess('toast.success.bandcamp.updatedOrderStatus');
          } else {
            this.toast.showError('toast.error.bandcamp.changeStatusNotUpdated');
          }
        },
        error: (err) => {
          this.toast.showError(err.error.message);
        },
      });
  }
}
