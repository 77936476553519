import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CompanyTierSettingService } from '../services/company-tier-setting.service';

@Injectable({
  providedIn: 'root',
})
export class NoBulkPickingEnabledGuard {
  private authService = inject(AuthService);
  private companyTierSettingService = inject(CompanyTierSettingService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let isBulkPickingEnabled = false;
    try {
      isBulkPickingEnabled = await this.companyTierSettingService.isBulkPickingEnabled(this.authService.user.companyId);
    } catch (error) {
      console.error(error);
    }
    if (isBulkPickingEnabled) {
      return true;
    }
    return this.router.navigate(['/orders']);
  }
}
