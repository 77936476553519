import { Routes } from '@angular/router';

export const orderRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./orders-table/orders-table.component').then((m) => m.OrdersTableComponent),
  },
  {
    path: 'order/:id',
    loadComponent: () =>
      import('../packing/packing-order/packing-order.component').then((m) => m.PackingOrderComponent),
  },
];
