import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { UserRole, UserRoleAll } from './shared/models/user';
import { ShopifyGuard } from './shared/guards/shopify.guard';
import { orderRoutes } from '@/order/order-routes';
import { packingRoutes } from '@/packing/packing-routes';
import { shipmentsRoutes } from '@/shipments/shipments-routes';
import { shippingRoutes } from '@/shipping/shipping-routes';
import { settingsRoutes } from '@/settings/settings-routes';
import { pickingRoutes } from '@/picking/picking-routes';
import { forumRoutes } from '@/forum/forum-routes';
import { dashboardRoutes } from '@/dashboard/dashboard-routes';
import { archiveRoutes } from '@/archive/archive-routes';
import { waybillRoutes } from '@/waybill/waybill-routes';
import { adminRoutes } from '@/admin/admin-routes';
import { inventoryRoutes } from '@/inventory/inventory-routes';
import { InventoryEnabledGuard } from './shared/guards/inventory-enabled.guard';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'admin/companies',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./core/login/login.component').then((m) => m.LoginComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'login-shopify',
    loadComponent: () => import('./core/login-shopify/login-shopify.component').then((m) => m.LoginShopifyComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'shopify',
    loadComponent: () =>
      import('./core/install-shopify/install-shopify.component').then((m) => m.InstallShopifyComponent),
    canActivate: [ShopifyGuard],
  },
  {
    path: 'register',
    loadComponent: () => import('./core/register/register.component').then((m) => m.RegisterComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'register-shopify',
    loadComponent: () =>
      import('./core/register-shopify/register-shopify.component').then((m) => m.RegisterShopifyComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./core/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import('./core/create-new-password/create-new-password.component').then((m) => m.CreateNewPasswordComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'join-company/:token',
    loadComponent: () => import('./core/join-company/join-company-component').then((m) => m.JoinCompanyComponent),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password-sent',
    loadComponent: () =>
      import('./core/email-sent-confirmation/email-sent-confirmation.component').then(
        (m) => m.EmailSentConfirmationComponent
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'connect-discogs',
    loadComponent: () =>
      import('./core/connect-discogs/connect-discogs.component').then((m) => m.ConnectDiscogsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard, RoleGuard, InventoryEnabledGuard],
    data: { allowedRoles: UserRoleAll },
    children: inventoryRoutes,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: dashboardRoutes,
  },
  {
    path: 'orders',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: orderRoutes,
  },
  {
    path: 'picking',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: pickingRoutes,
  },
  {
    path: 'packing',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: packingRoutes,
  },
  {
    path: 'shipments',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: shipmentsRoutes,
  },
  {
    path: 'waybills',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: waybillRoutes,
  },
  {
    path: 'shipping',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: shippingRoutes,
  },
  {
    path: 'archive',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: archiveRoutes,
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: settingsRoutes,
  },
  {
    path: 'forum',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: UserRoleAll },
    children: forumRoutes,
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, RoleGuard],
    data: { allowedRoles: [UserRole.superAdmin] },
    children: adminRoutes,
  },
];
