@if (isLoggedIn) {
<header [ngClass]="{ 'sidenav-closed': condensed }">
  <app-toolbar [isMobile]="isMobile" (menuToggle)="toggleSidenav()" [url]="url"> </app-toolbar>
</header>
} @if (isMobile && sidenavOpened) {
<div class="backdrop"></div>
} @if (isLoggedIn || isMobile) {
<aside [class.opened]="sidenavOpened" (click)="toggleSidenav()">
  <app-sidenav
    [isMobile]="isMobile"
    [isCondensed]="condensed"
    (menuToggle)="toggleSidenav()"
    (logout)="sidenavOpened = false"
    (click)="$event.stopPropagation()"
  ></app-sidenav>
</aside>
}
<main>
  <router-outlet></router-outlet>
</main>
