import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  standalone: true,
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() status: 'pending' | 'enabled' | 'disabled' | 'denied' | string = '';
  constructor() {}

  ngOnInit(): void {}
}
