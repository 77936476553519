import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyTierSetting } from '../models/company-tier-setting';

@Injectable({
  providedIn: 'root',
})
export class CompanyTierSettingService {
  constructor(private http: HttpClient) {}

  public async getCompanyTier(companyId: string): Promise<CompanyTierSetting> {
    return await this.http.get<CompanyTierSetting>(`company-tier-setting/${companyId}`).toPromise();
  }

  public async canAddShippingCarriers(companyId: string): Promise<boolean> {
    return await this.http.get<boolean>(`company-tier-setting/can-add-new-carriers/${companyId}`).toPromise();
  }

  public async isBulkPickingEnabled(companyId: string): Promise<boolean> {
    return await this.http.get<boolean>(`company-tier-setting/bulk-picking-status/${companyId}`).toPromise();
  }

  public async isOrderReceivedMessageEnabled(companyId: string): Promise<boolean> {
    return await this.http.get<boolean>(`company-tier-setting/order-received-message-status/${companyId}`).toPromise();
  }

  public async getOrderReceivedMessageTemplate(companyId: string): Promise<string | null> {
    return await this.http
      .get<string | null>(`company-tier-setting/order-received-message-template/${companyId}`)
      .toPromise();
  }

  public async getOrderShippedMessageTemplate(companyId: string): Promise<string | null> {
    return await this.http
      .get<string | null>(`company-tier-setting/order-shipped-message-template/${companyId}`)
      .toPromise();
  }
}
