import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Injectable } from '@angular/core';
import { ShippingCarrier, ShippingCarrierType } from '../models/shipping-carrier';
import { GeneralShippingSettingsOption } from '../models/company';
@Injectable({
  providedIn: 'root',
})
export class ShippingCarrierService extends CrudService<ShippingCarrier> {
  constructor(http: HttpClient) {
    super(http, 'shipping-carriers');
  }

  public async getMyShippingCarriers(): Promise<ShippingCarrier[]> {
    const data = await this.http.get<ShippingCarrier[]>(`${this.endpoint}/`).toPromise();

    return data.map((item) => ({ ...item }));
  }

  public async getMyShippingCarriersWithOptions(): Promise<GeneralShippingSettingsOption[]> {
    const data = await this.http.get<GeneralShippingSettingsOption[]>(`${this.endpoint}/carriers-options`).toPromise();

    return data.map((item) => ({ ...item }));
  }

  public async addDefaultShippingCarrier(data: AddDefaultShippingCarrierData): Promise<ShippingCarrier> {
    const result = await this.http.post<ShippingCarrier>(`${this.endpoint}/default-carrier`, data).toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  public async getFilteredServiceCodes(carrierId: string): Promise<string[]> {
    return await this.http.get<string[]>(`${this.endpoint}/filtered-service-codes/${carrierId}`).toPromise();
  }
}

export type AddDefaultShippingCarrierData = {
  shippingCarrierCredentialsId: string;
  name: string;
  type: ShippingCarrierType;
};
