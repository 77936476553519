import { Component, OnDestroy, inject } from '@angular/core';
import { AuthService } from '@/shared/services/auth.service';
import { ToastService } from '@/shared/services/toast.service';
import { User, UserRoleTranslations } from '@/shared/models/user';
import { Subscription } from 'rxjs';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { AvatarComponent } from '@/shared/components/avatar/avatar.component';
import { LoadingDirective } from '@/shared/directives/loading.directive';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  imports: [
    MatCard,
    MatMenuTrigger,
    MatCardContent,
    AvatarComponent,
    MatMenu,
    MatMenuItem,
    LoadingDirective,
    TranslatePipe,
  ],
})
export class UserDropdownComponent implements OnDestroy {
  private toast = inject(ToastService);
  private authService = inject(AuthService);

  user: User;
  userRole: string;
  isLoggingOut = false;
  isDropdownOpen: boolean = false;
  private userSubscription: Subscription;

  ngOnInit(): void {
    this.userSubscription = this.authService.user$.subscribe((user) => {
      this.user = user;
      this.userRole = UserRoleTranslations[this.user.role];
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async logout() {
    try {
      this.isLoggingOut = true;
      await this.authService.logout(this.authService.user.email);
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoggingOut = false;
    }
  }
}
