import { Routes } from '@angular/router';

export const settingsRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./settings.component').then((m) => m.SettingsComponent),
    data: { visibleComponent: 'users' },
  },
  {
    path: 'users',
    loadComponent: () => import('./settings.component').then((m) => m.SettingsComponent),
    data: { visibleComponent: 'users' },
  },
  {
    path: 'sales-channels',
    loadComponent: () => import('./settings.component').then((m) => m.SettingsComponent),
    data: { visibleComponent: 'salesChannels' },
  },
  {
    path: 'shipping-carriers',
    loadComponent: () => import('./settings.component').then((m) => m.SettingsComponent),
    data: { visibleComponent: 'shippingCarriers' },
  },
];
