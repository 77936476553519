<div class="scrollable-container">
  <nav [class.condensed]="condensed">
    @if (!isMobile) {
    <app-logo [withTitle]="!isCondensed" [graysLevel]="'900'"></app-logo>
    } @if (isMobile) {
    <button mat-icon-button class="close-icon" (click)="menuToggle.emit()">
      <i class="icon">close</i>
    </button>
    }

    <section class="navigation">
      @for (item of menuItems; track item; let i = $index) {
      <div class="menu-item" [class.expanded]="item.expanded">
        <!-- Menu is not condensed or item does not have a submenu -->
        @if (!item.submenu || !condensed) {
        <button
          class="menu-button"
          mat-button
          (click)="itemClick(item)"
          [routerLink]="item.link || null"
          [routerLinkActive]="item.link ? 'active' : ''"
          [queryParamsHandling]="item.link === activeLink ? 'preserve' : null"
          [matTooltip]="item.title | translate"
          [matTooltipDisabled]="!condensed"
          matTooltipPosition="right"
        >
          @if (item?.icon) {
          <img class="start-icon" [src]="item?.icon" />
          }
          <span>{{ item.title | translate }}</span>
        </button>
        }
        <!-- Menu is condensed and item has a submenu -->
        @if (item.submenu && condensed) {
        <button
          class="menu-button"
          mat-button
          [matTooltip]="item.title | translate"
          [matTooltipDisabled]="!condensed"
          matTooltipPosition="right"
          [matMenuTriggerFor]="itemSubmenu"
        >
          @if (item?.icon) {
          <img class="start-icon" [src]="item?.icon" />
          }
          <span>{{ item.title | translate }}</span>
        </button>
        <mat-menu #itemSubmenu="matMenu">
          @if (item.submenu) { @for (subitem of item.submenu; track subitem) {
          <button
            mat-menu-item
            class="menu-button"
            [routerLink]="item?.link || []"
            [routerLinkActive]="item.link ? 'active' : ''"
          >
            {{ subitem.title | translate }}
          </button>
          } }
        </mat-menu>
        } @if (item.submenu && !condensed) {
        <div
          class="expansion-panel"
          [ngStyle]="{ maxHeight: item?.submenu?.length * 42 + (item?.submenu?.length - 1) * 2 + 4 + 'px' }"
        >
          @for (subitem of item.submenu; track subitem) {
          <button
            mat-button
            class="menu-button"
            [routerLink]="subitem?.link || []"
            [routerLinkActive]="subitem.link ? 'active' : ''"
          >
            {{ subitem.title | translate }}
          </button>
          }
        </div>
        }
      </div>
      }
    </section>

    <!-- Just functional logout button-->
    <div class="collapse-button">
      <button
        class="menu-button logout-button loading-dark"
        mat-button
        (click)="onLogout()"
        [appLoading]="isLoggingOut"
        [matTooltip]="'navbar.logout' | translate"
        [matTooltipDisabled]="!condensed"
        matTooltipPosition="right"
      >
        <i class="icon start-icon">logout</i>
        <span>{{ 'navbar.logout' | translate }}</span>
      </button>
      @if (!isMobile) {
      <button
        class="menu-button loading-dark"
        mat-button
        [matTooltip]="'navbar.collapseMenu' | translate"
        [matTooltipDisabled]="!condensed"
        matTooltipPosition="right"
        (click)="menuToggle.emit()"
      >
        @if (!condensed) {
        <img class="start-icon" src="/assets/icons/collapse.svg" />
        } @if (condensed) {
        <img class="start-icon" src="/assets/icons/expand.svg" />
        }
        <span>{{ 'navbar.collapseMenu' | translate }}</span>
      </button>
      }
    </div>
  </nav>
</div>
