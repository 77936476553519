import { Routes } from '@angular/router';
import { NoBulkPickingEnabledGuard } from '@/shared/guards/no-bulk-picking-enabled.guard';

export const pickingRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./picking.component').then((m) => m.PickingComponent),
    canActivate: [NoBulkPickingEnabledGuard],
  },
  {
    path: 'crate/:id',
    loadComponent: () => import('./picking-flow/picking-flow.component').then((m) => m.PickingFlowComponent),
  },
];
