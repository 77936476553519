import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { Company } from '@/shared/models/company';
import { SuperAdminCompanyService } from '@/shared/services/super-admin-company.service';
import { BackComponent } from '@/shared/components/back/back.component';
import { MatButton } from '@angular/material/button';
import { UserDropdownComponent } from '../user-dropdown/user-dropdown.component';
import { TranslatePipe } from '@ngx-translate/core';

function isValidMongoId(id: string): boolean {
  const objectIdRegex = /^[a-f\d]{24}$/i;
  return objectIdRegex.test(id);
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  imports: [BackComponent, MatButton, UserDropdownComponent, TranslatePipe],
})
export class ToolbarComponent implements OnInit, OnChanges {
  private superAdminCompanyService = inject(SuperAdminCompanyService);

  @Input() isMobile = false;
  @Input() url: string = '';

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();

  title: string = '';
  canGoBack: boolean = false;
  withTitle: boolean = true;
  company: Company;

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    this.canGoBack = this.url.includes('/order/');
    this.withTitle = !this.canGoBack;
    const urlParts = this.url.split('/');
    this.title = urlParts[1].split('?')[0];

    const isSuperAdminRoute = this.url.startsWith('/admin');
    if (isSuperAdminRoute) {
      this.title = urlParts[urlParts.length - 1].split('?')[0];
      const companyId = urlParts[urlParts.length - 2];
      const isCompaniesRoute = this.url.startsWith('/admin/companies');
      if (isCompaniesRoute && isValidMongoId(companyId) && (!this.company || this.company._id !== companyId)) {
        const company = await this.superAdminCompanyService.getOne(companyId);
        this.company = company;
        this.canGoBack = true;
      }
      this.canGoBack = this.withTitle = this.title !== 'admin';
    }
  }

  toCamelCase(input: string): string {
    return input
      .split('-') // Split the string into words using the hyphen as a delimiter
      .map(
        (word, index) =>
          index === 0
            ? word.toLowerCase() // The first word is in lowercase
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter of subsequent words
      )
      .join(''); // Join the words back together without spaces
  }
}
