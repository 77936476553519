import { Routes } from '@angular/router';
import { NoBulkPickingEnabledGuard } from '@/shared/guards/no-bulk-picking-enabled.guard';

export const packingRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./packing.component').then((m) => m.PackingComponent),
    canActivate: [NoBulkPickingEnabledGuard],
  },
  {
    path: 'order/:id',
    loadComponent: () => import('./packing-order/packing-order.component').then((m) => m.PackingOrderComponent),
  },
];
