import { Location } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconButton } from '@angular/material/button';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
  imports: [MatIconButton, TranslatePipe],
})
export class BackComponent implements OnInit {
  private router = inject(Router);
  private location = inject(Location);

  @Input() route: string;
  @Input() onlyArrow: boolean = false;

  ngOnInit(): void {}

  onBack() {
    if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigateByUrl(this.route);
    }
  }
}
