<section class="toolbar">
  <!-- <div *ngIf="isMobile && !canGoBack" class="logo-container">
  <img src="/assets/images/logo.png" alt="Logo" class="logo" />
</div> -->
  @if (canGoBack) {
  <app-back route="/orders" [onlyArrow]="withTitle"></app-back>
  } @if (isMobile) {
  <div class="toggle-menu">
    <button mat-button class="menu-button" (click)="menuToggle.emit()">
      <img src="/assets/icons/menu.svg" />
    </button>
  </div>
  } @if (!isMobile && withTitle) {
  <div class="title">
    <h2>{{ 'navbar.' + toCamelCase(title) | translate: { name: this.company?.name } }}</h2>
  </div>
  }
  <div>
    <app-user-dropdown></app-user-dropdown>
  </div>
</section>
