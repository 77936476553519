@if (user) {
<mat-card [matMenuTriggerFor]="settingsMenu">
  <mat-card-content>
    <div class="avatar-and-name">
      <app-avatar [fullName]="user.fullName" width="40" height="40"></app-avatar>
      <div class="user-info">
        <h5>{{ user.fullName }}</h5>
        <span>{{ userRole }}</span>
      </div>
      <img class="dropdown-button" src="/assets/icons/chevron-down.svg" />
    </div>
  </mat-card-content>
</mat-card>
}
<mat-menu #settingsMenu>
  <menu mat-menu-item [appLoading]="isLoggingOut" (click)="logout()">{{ 'navbar.logout' | translate }}</menu>
</mat-menu>
