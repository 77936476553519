import { enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { JwtInterceptor } from './app/shared/services/jwt.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectCountryModule } from '@/shared/components/select-country';
import { AppComponent } from './app/app.component';
import LaraTheme from '@primeng/themes/lara';
import { provideRouter } from '@angular/router';
import { appRoutes } from '@/app-routes';
import {
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
  MatDateFormats,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeEnGb from '@angular/common/locales/en-GB';

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.name,
});

if (environment.production) {
  enableProdMode();
}
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

registerLocaleData(localeEnGb);

export const DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false,
    } as Intl.DateTimeFormatOptions,
  },
};

bootstrapApplication(AppComponent, {
  providers: [
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    importProvidersFrom(BrowserModule, MatSelectCountryModule.forRoot('en')),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    provideNativeDateAdapter(DATE_FORMATS),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: LaraTheme,
        options: {
          darkModeSelector: false,
        },
      },
    }),
    provideAnimations(),
    provideRouter(appRoutes),
  ],
}).catch((err) => console.error(err));
