import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserRole } from '@/shared/models/user';
import { AuthService } from '@/shared/services/auth.service';
import { CompanyTierSettingService } from '@/shared/services/company-tier-setting.service';
import { ShippingCarrierService } from '@/shared/services/shipping-carrier.service';
import { ToastService } from '@/shared/services/toast.service';
import { LogoComponent } from '@/shared/components/logo/logo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgStyle } from '@angular/common';
import { LoadingDirective } from '@/shared/directives/loading.directive';
import { TranslatePipe } from '@ngx-translate/core';

export interface MenuItem {
  title: string;
  link?: string;
  icon?: string;
  submenu?: { title: string; link: string }[];
  expanded?: boolean;
  roles: UserRole[];
}

const userRoles = [UserRole.admin, UserRole.employee];

let allMenuItems: MenuItem[] = [
  {
    title: 'navbar.dashboard',
    link: '/dashboard',
    icon: '/assets/icons/globe.svg',
    roles: userRoles,
  },
  {
    title: 'navbar.orders',
    link: '/orders',
    roles: userRoles,
    icon: '/assets/icons/orders-crate.svg',
  },
  {
    title: 'navbar.picking',
    link: '/picking',
    icon: '/assets/icons/picking.svg',
    roles: userRoles,
  },
  {
    title: 'navbar.packing',
    link: '/packing',
    icon: '/assets/icons/packing.svg',
    roles: userRoles,
  },
  {
    title: 'navbar.waybills',
    link: '/waybills',
    icon: '/assets/icons/waybills.svg',
    roles: userRoles,
  },
  {
    title: 'navbar.shipments',
    link: '/shipments',
    icon: '/assets/icons/shipments.svg',
    roles: userRoles,
  },
  {
    title: 'navbar.archive',
    link: '/archive',
    icon: '/assets/icons/archive.svg',
    roles: userRoles,
  },
  {
    title: 'navbar.settings',
    icon: '/assets/icons/settings.svg',
    link: '/settings',
    roles: userRoles,
  },
  {
    title: 'navbar.forum',
    icon: '/assets/icons/forum-icon.svg',
    link: '/forum',
    roles: userRoles,
  },
  {
    title: 'navbar.companies',
    icon: '/assets/icons/globe.svg',
    link: '/admin/companies',
    roles: [UserRole.superAdmin],
  },
  {
    title: 'navbar.companyTierSetting',
    icon: '/assets/icons/globe.svg',
    link: '/admin/company-tier-setting',
    roles: [UserRole.superAdmin],
  },
  {
    title: 'navbar.shippingRatesManagement',
    icon: '/assets/images/shipping-rates.png',
    link: '/admin/shipping-rates-management',
    roles: [UserRole.superAdmin],
  },
  {
    title: 'navbar.shippingCarrierCredentials',
    icon: '/assets/images/credentials-icon.png',
    link: '/admin/shipping-carrier-credentials',
    roles: [UserRole.superAdmin],
  },
];

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  imports: [
    LogoComponent,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    MatTooltip,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    NgStyle,
    LoadingDirective,
    TranslatePipe,
  ],
})
export class SidenavComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private authService = inject(AuthService);
  private toast = inject(ToastService);
  private shipEngine = inject(ShippingCarrierService);
  private companyTierSettingService = inject(CompanyTierSettingService);

  @Input() isMobile = false;
  @Input() isCondensed = false;

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  activeLink: string;
  isLoggingOut = false;
  isPNEnabled = true;
  isBulkPickingEnabled: boolean = false;

  menuItems: MenuItem[] = [];

  async ngOnInit() {
    if (this.authService.user && this.authService.user.role !== UserRole.superAdmin) {
      try {
        const shippingCarriers = await this.shipEngine.getMyShippingCarriers();
        this.isPNEnabled = shippingCarriers.some((item) => item.type === 'postNord' && item.status === 'enabled');
        this.isBulkPickingEnabled = await this.companyTierSettingService.isBulkPickingEnabled(
          this.authService.user.companyId
        );
      } catch (error) {
        console.error(error);
      }
    }
    this.menuItems = this.getMenuItems();
    this.activeLink = this.router.url.split('?')[0];
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeLink = event.url.split('?')[0];
      }
    });
    this.setExpanded();
  }

  getMenuItems() {
    if (!this.authService.user) return [];
    if (!this.isPNEnabled) allMenuItems = allMenuItems.filter((item) => item.title != 'navbar.waybills');
    if (!this.isBulkPickingEnabled)
      allMenuItems = allMenuItems.filter((item) => item.title != 'navbar.picking' && item.title != 'navbar.packing');

    return allMenuItems.filter((item) => item.roles.includes(this.authService.user.role));
  }

  get condensed() {
    return this.isMobile ? false : this.isCondensed;
  }

  setExpanded() {
    this.menuItems
      .filter((item) => item.submenu != null)
      .forEach((item) => {
        item.expanded = item.submenu.map((i) => i.link).includes(this.activeLink);
      });
  }

  itemClick(item: MenuItem) {
    if (item.submenu && !this.condensed) {
      item.expanded = !item.expanded;
    }
  }
  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.authService.logout(this.authService.user.email);
      this.logout.emit();
    } catch (e) {
      this.toast.showError(e, false, '', true);
    } finally {
      this.isLoggingOut = false;
    }
  }

  ngOnDestroy() {
    this.toast.onDismiss();
  }
}
